/**************************************/
/* GENRRALITIES */
/* ************************************/
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

:root {
  box-sizing: border-box;
  font-size: 62.5%;
  --color-text: #323;
  --color-violet: #914669;
  --color-white: #ffffff;
  --color-orange: rgba(255, 166, 0, 0.694);
  --color-PapayaWhip: #faedcd;
  --color-PapayaWhipc: #f7f2e660;
  --colorBlueLink: #0072b1;
}

@font-face {
  font-family: "teslaregular";
  src: url("../src/font/tesla/tesla-webfont.woff2") format("woff2"),
    url("../src/font/tesla/tesla-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.container {
  max-width: 1400px;
  background: url("../public/img/plant-g3c5861582_1920.jpg") center/cover;
  padding-top: 3rem;
  padding-bottom: 6rem;
}

body {
  font-size: 1.6rem;
  color: var(--color-text);
  font-family: "Roboto", sans-serif;
  margin: 0;
  /* transition: all 0 ease-out; */
}

h1,
.sectionHome h3,
.menu-span {
  font-family: "teslaregular";
}

.titlePages {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 3.2rem;
  color: var(--color-violet);
  border-bottom: 0.2rem solid var(--color-orange);
  padding: 2rem 3rem;
  margin-left: 7rem;
  margin-right: 7rem;
}

.titleStyle {
  margin-top: 9rem;
  margin-bottom: 6rem;
}

h2 {
  text-align: center;
  padding: 2rem 0 2rem 0;
}

/* A VOIR ********************************** */

.flex {
  display: flex;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.jc-center {
  justify-content: center;
}

.ai-center {
  align-items: center;
}

.containerSection {
  background-color: var(--color-white);
  margin: 6rem;
  padding: 0 2rem 4rem 2rem;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.containerFixed {
  position: fixed;
  top: 0;
  width: 100%;
  height: 7rem;
  z-index: 200;
  background-color: var(--color-white);
}

/* ************************************************ */
/*************** SECTION ANIMATION **************/
.oh {
  overflow: hidden;
}

.oh__inner-all {
  padding-bottom: 0;
  margin-bottom: 0;
  display: inline-block;
  transform-origin: 0% 50%;
  will-change: transform;
  animation: appearAnimation 1s ease forwards;
}

.oh__inner-all-title {
  margin-bottom: 0;
  transform-origin: 0% 50%;
  animation: appearAnimation 1s ease forwards;
}

.oh-all-title {
  overflow: hidden;
  margin-bottom: 0;
  padding-bottom: 0;
}

@keyframes appearAnimation {
  0% {
    transform: translate(0, 150%) rotate(5deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

/*******************************************/
/************* PAGE HOME ***************/
.containerHome {
  background: url("../public/img/fog-g3114b46c3_1920.jpg");
  height: 100vh;
  position: relative;
}

.backgroundNone {
  background: none;
}

.displayNone {
  display: none;
  opacity: 0;
}

.imgSecondHome {
  background: url("../public/img/plants-g3737d085f_1920.jpg") center/cover;
  z-index: 1;
  height: 70%;
  width: 70%;
  position: absolute;
  right: 3rem;
  top: 3rem;
}

.imgFirstHome {
  background: url("../public/img/plant-g3c5861582_1920.jpg") center/cover;
  height: 70%;
  width: 70%;
  position: absolute;
  left: 3rem;
  bottom: 3rem;
  z-index: 2;
}

.containerBiography h3,
.imgFirstHome h3 {
  padding-left: 1rem;
}

.imgShowSection {
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
}

h1 {
  font-size: 4rem;
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
}

.welcome {
  margin-top: 10rem;
  padding-left: 1rem;
  width: 50%;
}

.imgShowSection .welcome {
  margin-top: 20rem;
}

.welcome h2 {
  font-size: 3rem;
  padding-bottom: 0;
}

.welcome p {
  font-size: 2.5rem;
  color: var(--color-violet);
  text-align: left;
}

.imgShowSection .welcome h2 {
  font-size: 3.5rem;
}

.imgShowSection .welcome p {
  font-size: 2.7rem;
}

.titleName {
  font-size: 2rem;
}

/*** home amimation ***/
.imgShowSection .oh__inner-home {
  padding-bottom: 0;
  margin-bottom: 0;
  display: inline-block;
  transform-origin: 0% 50%;
  will-change: transform;
  animation: appearAnimation 1s ease forwards;
}

/*******************************************/
/*************** PAGE BIOGRAPHY **************/
.containerBiography {
  /* background-color: #f0f0f0; */
  background: url("../public/img/plant-g3c5861582_1920.jpg") center/cover;
  padding-top: 14rem;
  padding-bottom: 6rem;
}

.headerBiography {
  color: var(--color-orange);
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--color-white);
  padding-bottom: 2rem;
}

.burgerBiography .containerBurger {
  z-index: 105;
}

.headerBiography h2 {
  font-size: 3.6rem;
  font-family: "teslaregular";
  padding-top: 4rem;
  color: var(--color-violet);
}

.headerBiography p {
  font-size: 1.8rem;
  font-style: italic;
  margin-top: 0;
  padding-top: 3rem;
  margin-left: 1rem;
  margin-left: 1rem;
}

.containerBiography #about {
  text-align: left;
  margin-top: 9rem;
}

.containerBiography section h3 {
  font-size: 2.8rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid var(--colorBlueLink);
  padding-bottom: 1rem;
  color: var(--color-violet);
  text-align: center;
}

.skills {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.skill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.skill p {
  padding-top: 0rem;
  margin-top: 0rem;
  margin-bottom: 0.8rem;
}

.skill img {
  width: 6rem;
  height: 6rem;
  margin-left: 2.3rem;
  margin-right: 2.3rem;
}

/*** biography animation ***/
.oh__inner-biography {
  padding-bottom: 0;
  margin-bottom: 0;
  transform-origin: 0% 50%;
  will-change: transform;
  animation: appearAnimation 1s ease forwards;
}

/*******************************************/
/*************** PAGE PROJECT **************/
.projects {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 4rem;
}

.project {
  width: 30rem;
  margin: 1rem;
  margin-bottom: 3rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  height: 40rem;
}

.projects a {
  text-decoration: none;
  color: var(--color-orange);
}

.projectDetails p {
  text-decoration: none;
  color: var(--color-violet);
  margin-left: 1rem;
  margin-right: 1rem;
}

.project:hover {
  transform: translateY(-5px);
}

.project img {
  width: 60%;
  padding: 1rem;
  object-fit: fill;
}

.project-details {
  padding: 20px;
}

.project-details h2 {
  margin-top: 0;
}

.project-details p {
  color: #888;
}

/*******************************************/
/*************** PAGE EXPERIENCES **************/
.article-vertical {
  margin-top: 5rem;
}

.vertical-timeline {
  background-color: var(--color-violet);
  border-radius: 0.5rem;
}

.vertical-timeline-element {
  padding: 1.5rem;
  line-height: 0;
}

.vertical-timeline-element-title {
  line-height: 1.2;
}

.vertical-timeline.vertical-timeline--two-columns:before {
  background-color: var(--color-orange);
  margin-left: 1rem;
}

.even-element .vertical-timeline-element-date {
  text-align: left;
}

.vertical-timeline-element-icon {
  margin-left: 1rem;
}

.vertical-timeline .vertical-timeline-element-description {
  font-size: 1.7rem;
}

.vertical-timeline .vertical-timeline-element-date {
  color: var(--color-orange);
  font-size: 1.8rem !important;
}

/******** curriculum Vitae ********/
.containercurriculumVitae {
  padding: 2rem;
}

.curriculumVitae {
  width: 95%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 3rem;
  border: 0.5rem solid var(--color-violet);
  border-radius: 0.5rem;
}

.curriculumVitae a {
  text-decoration: none;
  color: var(--color-orange);
}

.curriculumVitae a:hover {
  color: var(--color-violet);
}

.btnShowCloseCv {
  margin-top: 2rem;
  all: unset;
  padding: 0.6rem 2.5rem;
  border: 0.3rem solid var(--color-violet);
  border-radius: 0.3rem;
  color: var(--color-violet);
}

.btnShowCloseCv:hover {
  border: 0.3rem solid var(--color-orange);
}

.btnCloseCv {
  margin-top: 2rem;
}

.showCv {
  margin-bottom: 2rem;
}

/*** experiences-cv animation ***/
.oh__inner-all-cv {
  display: inline-block;
  transform-origin: 0% 50%;
  will-change: transform;
  animation: appearAnimation 1s ease forwards;
}

/*******************************************/
/*************** PAGE CONTACT **************/
.formContainer {
  background-color: var(--color-white);
  border-radius: 3rem;
  color: var(--color-violet);
  transition: all 0.5s ease-in-out;
  font-size: 2.2rem;
  margin: 0 auto;
  width: 100%;
}

.formOpacity {
  opacity: 0.2;
}

.form-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  text-align: center;
}

address {
  width: 40%;
  font-weight: bold;
}

address .icon {
  padding-right: 1rem;
  color: var(--color-orange);
}

.faLinkedin {
  color: var(--colorBlueLink);
}

address a {
  color: var(--color-violet);
  text-decoration: none;
}

.city-bordeaux {
  margin-top: -2rem;
}

.fa-linkedin {
  font-size: 3.5rem;
}

.group {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.group article {
  width: 70%;
}

.form-group input,
.form-group textarea {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border: 0.2rem solid var(--color-violet);
  border-radius: 0.6rem;
  outline: none;
  width: 100%;
  background-color: #f9f9f9;
}

.form-group input:focus,
textarea:focus {
  border: 0.2rem solid var(--color-orange);
}

textarea {
  height: 13rem;
}

input[type="submit"] {
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: var(--color-violet);
  background-color: var(--color-orange);
  font-size: 2rem;
}

input[type="submit"]:hover {
  border: 0.2rem solid var(--color-orange);
}

/*************** MODAL **************/
.containerModal {
  position: absolute;
  top: 60rem;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 350px;
  height: 120px;
}

.modal {
  padding-top: 1rem;
  margin: 3rem;
  height: 80%;
  border-radius: 0.7rem;
  box-shadow: 0 0 0.5rem 0;
  background-color: var(--color-PapayaWhip);
}

.btnModal {
  border: none;
  background-color: var(--color-violet);
  color: var(--color-white);
  border-radius: 0.3rem;
  padding: 0.6rem 10rem;
}

/*************** Subcomponent NAV BAR **************/
.oh {
  overflow: hidden;
}

.oh__inner {
  display: inline-block;
  transform-origin: 0% 50%;
  will-change: transform;
  margin-left: 2rem;
}

.menu {
  position: absolute;
  pointer-events: none;
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 0;
  width: 100%;
  height: auto;
  transform: translateY(-50%);
  counter-reset: menucounter;
  display: grid;
  grid-template-columns: auto;
  align-content: center;
  justify-content: center;
}

.open-menu {
  pointer-events: auto;
  opacity: 1;
}

.menu__item {
  grid-column: 2;
  line-height: 1;
  display: block;
  position: relative;
  text-align: left;
}

.menu__item-link {
  text-decoration: none;
  line-height: 1.25;
  margin-top: calc(-0.15 * var(--font-size-xlarge));
  font-size: var(--font-size-xlarge);
  cursor: pointer;
  position: relative;
  padding: 0 calc(0.45 * var(--font-size-xlarge));
  color: var(--color-violet);
  font-size: 7rem;
  transition: transform 0.3s ease-in-out;
}

.menu__item-link:hover {
  color: var(--color-orange);
}

.menu__item-link::before {
  position: absolute;
  left: -1.5rem;
  top: 18%;
  text-align: right;
  counter-increment: menucounter;
  content: counters(menucounter, ".", decimal-leading-zero);
  opacity: 0;
  line-height: 1;
  padding-right: 0.5rem;
  font-size: 1.6rem;
  margin-left: -0.5rem;
  color: var(--color-text);
  transform: scale3d(0, 0, 0);
  display: grid;
  place-items: center;
}

.menu__item-link:hover::before {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.menu .menu__item-link {
  transform: translate(0, 150%) rotate(5deg);
  transition: transform 1s, rotate 1s;
}

.open-menu .menu__item-link {
  transform: translate(0, 0);
}

/****************** SubComponent MENU **************/

.containerMenu {
  position: absolute;
  top: 0.7rem;
  right: 1rem;
  font-size: 2.5rem;
  cursor: pointer;
}

.menu-span::before {
  content: "Menu";
  color: var(--color-violet);
}

.menu-span::after {
  position: absolute;
  content: "";
  border-bottom: 0.3rem solid var(--color-violet);
  transform: scaleX(1);
  transform-origin: 0 50%;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.menu-span:hover:after {
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-delay: 0.3s;
}

/**************** SubComponent BURFER ***************/

.containerBurger {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  height: 5rem;
  width: 5rem;
  overflow: hidden;
}

.burgerSpan {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 5rem;
  height: 0.7rem;
  top: 2rem;
  background-color: var(--color-violet);
  transition: all 0.3s;
  border-radius: 5px;
  display: block;
}

.imgSecondHome .burgerSpan {
  width: 3rem;
  height: 0.4rem;
  top: 0.8rem;
  right: -0.5rem;
}

.imgSecondHome .cross {
  animation: none;
}

.firstBurger {
  transform: rotate(45deg);
}

.secondBurger {
  display: none;
}

.lastBurger {
  transform: rotate(-45deg);
}

/* .containerBurger:hover .burgerSpan {
  background-color: var(--color-orange);
} */

/**** burger animation */
.cross {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
  transform-origin: 0% 50%;
  will-change: transform;
  animation: appearAnimation 1s ease forwards;
}

.spanCross {
  position: absolute;
  top: 0;
  right: 0;
}
