/****************************************** */
/******* Media screen max-width:1100px *****/

@media screen and (max-width:1100px) {

    /**************** PAGE HOME ***************/

    .imgFirstHome {
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
    }


    .secondHome {
        margin-top: 16rem;
    }


    /**************** PAGE CONTACT ***************/
    .formContainer {
        font-size: 1.8rem;
    }

    .group article {
        width: 85%;

    }


}

/****************************************** */
/****** Media screen max-width:900px *****/

@media screen and (max-width:900px) {

    /**************** PAGE HOME ***************/


    .titleName {
        font-size: 1.8rem;

    }

    .imgShowSection .welcome {
        transition: transform .3s ease-in-out;
    }

    .imgShowSection .welcome h2 {
        font-size: 2.7rem;
    }

    .imgShowSection .welcome p {
        font-size: 2.3rem;
    }


    /**************** PAGE CONTACT ***************/

    .form-contact {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;


    }

    .group {
        width: 85%;
    }

    input[type="submit"] {
        margin-bottom: 0;

    }

    address {
        padding-top: 1rem;
        text-align: center;
        margin-top: 6rem;
        margin-bottom: 3rem;
        width: 100%;
        padding-right: 0;
    }


}

/****************************************** */
/****** Media screen max-width:768px ******/

@media screen and (max-width:768px) {

    /**************** GENRRALITIES ***************/
    .titlePages {
        margin-left: 0;
        margin-right: 0;
    }

    /**************** PAGE HOME ***************/

    .titleName {
        font-size: 1.6rem;
    }

    .imgShowSection .welcome {
        margin-top: 16rem;
    }

    .imgShowSection .welcome h2 {
        font-size: 2.2rem;
    }

    .imgShowSection .welcome p {
        font-size: 2rem;
    }

    /**************** PAGE EXPERIENCES ***************/

    .containerExperiences .containerSection {
        padding-left: 0;
        padding-right: 0;
        margin-left: 3rem;
        margin-right: 3rem;
    }


    /**************** PAGE BIOGRAPHY ***************/

    .biography h2 {
        font-size: 2.3rem;
    }


    /**************** PAGE CONTACT ***************/
    .group {
        width: 90%;
    }


    /**************** PAGE PROJRCT ***************/
    .project {
        width: 250px;
    }

    .project img {
        width: 70%;
        padding: 0;
        margin-top: 1rem;
    }

    /*************** Subcomponent NAV BAR **************/
    .menu__item-link {
        font-size: 5.5rem;
        margin: 1rem;
    }
}



/****************************************** */
/**** Media screen max-width:550px ********/

@media screen and (max-width:550px) {

    /**************** GENRRALITIES ***************/
    .titlePages {
        font-size: 1.8rem;
    }

    .containerSection {
        margin-left: 0;
        margin-right: 0;

    }

    /**************** PAGE HOME ***************/
    .imgShowSection .welcome {
        margin-top: 20rem;
    }

    /**************** PAGE EXPERIENCES ***************/
    .curriculumVitaeShow {
        width: 100%;
        border: none;
        padding-left: 0rem;
        padding-right: 0rem;
        margin-left: 0rem;
        margin-right: 0rem;
        max-width: 550px;

    }

    .containercurriculumVitaeShow {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    .containerExperiences .containerSection {
        padding-left: 0;
        padding-right: 0;
        margin-left: .5rem;
        margin-right: .5rem;
    }

    .containerExperiences .titlePages {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .articleVitae embed {
        width: 40rem;

    }


    /**************** PAGE BIOGRAPHY ***************/
    .headerBiography h2 {
        font-size: 2rem;
    }

    .burgerBiography {
        margin-bottom: 3rem;
    }

    #about h3,
    #containerSkills h3 {
        font-size: 1.9rem;
    }




    /**************** SubComponent BURFER ***************/
    .containerBurger {
        width: 3rem;
        height: 3rem;
    }

    .containerBurger .burgerSpan {
        top: 1rem;
        right: 0;
        height: .4rem;
        width: 3rem;
    }


    /* .burgerSpan:hover {
        background: unset;
    } */


    .sectionHomeBurger .burgerSpan {
        top: -1rem;
    }

    .sectionHomeBurger .firstBurger {
        transform: rotate(0deg);
        margin-top: 1rem;
    }


    .sectionHomeBurger .secondBurger {
        display: block;
        transform: rotate(0deg);
        margin-top: 2rem;
    }

    .sectionHomeBurger .lastBurger {
        transform: rotate(0deg);
        margin-top: 3rem;
    }

    /*************** Subcomponent NAV BAR **************/
    .menu__item-link {
        font-size: 3rem;
        margin: 2.5rem;
    }

    /**************** PAGE CONTACT ***************/


    .formContainer {
        width: 100%;
    }

    .group {
        width: 100%;

    }

    .form-contact {
        width: 100%;

    }

    /**************** MODAL ***************/
    .containerModal {
        width: 250px;
        height: 120px;
        top: 50rem;
    }

    .messageModal {
        margin-top: 1rem;

    }

    .btnModal {
        padding: .6rem 7rem;
    }

    /**************** PAGE PROJECT ***************/
    .projectDetails h2 {
        padding: 0;
        font-size: 2rem;
    }

    .projectDetails p {

        font-size: 1.5rem;
    }

    .project {
        width: 14rem;
        margin: 1rem;
        padding: 1rem;
        line-height: 2rem;
        height: 30rem;

    }

    .project img {
        width: 80%;
        height: 35%;
        padding: 0;
        margin-top: 1rem;
    }



}

/****************************************** */
/**** Media screen max-width:550px ********/

@media screen and (max-width:500px) {

    /**************** PAGE EXPERIENCES ***************/
    .curriculumVitaeShow {
        max-width: 500px;
    }
}